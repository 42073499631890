export const MainConfig = {
  AppDetails: {
    app_name: "Doki",
    app_description: "Best delivery app service",
    app_identifier: "com.dokideliveryapp.app",
    ios_app_version: "1.0.0",
    android_app_version: 1,
  },

  // old config file
  // measurementId: "G-YPJN3L0L8X"
  // FirebaseConfig: {
  //   apiKey: "AIzaSyCV5g5byVu6eko9dzQo9rwZsvZcgIrsrN8",
  //   authDomain: "doki-app-new.firebaseapp.com",
  //   databaseURL: "https://doki-app-new-default-rtdb.firebaseio.com",
  //   projectId: "doki-app-new",
  //   storageBucket: "doki-app-new.appspot.com",
  //   messagingSenderId: "561154186663",
  //   appId: "1:561154186663:web:f6d87ebb0eb9fba6702a82",
  //   measurementId: "G-EJ7ZXNET6W",
  // },

  // FirebaseConfig: {
  //   apiKey: "AIzaSyArA45KCmLjSr8g_soehITPgotyZGjZhqw",
  //   authDomain: "doki-main-ms.firebaseapp.com",
  //   databaseURL: "https://doki-main-ms-default-rtdb.firebaseio.com",
  //   projectId: "doki-main-ms",
  //   storageBucket: "doki-main-ms.appspot.com",
  //   messagingSenderId: "429781303125",
  //   appId: "1:429781303125:web:c958e16f12e08bfdf879dc",
  //   measurementId: "G-VKKLF544GM",
  // },

  FirebaseConfig: {
    apiKey: "AIzaSyA77o_ssfzOxjaAaaUM_qexNaveUduv9rg",
    authDomain: "doki-delivery-app.firebaseapp.com",
    projectId: "doki-delivery-app",
    storageBucket: "doki-delivery-app.appspot.com",
    messagingSenderId: "30878029679",
    appId: "1:30878029679:web:391949818e9217d35cfa9a",
    measurementId: "G-9MLBLLTLHK"
  },

  Google_Map_API_Key: {
    ios: "AIzaSyC8DThAeEmhr9_DdDv7eaeotfJlXomk6L4",
    android: "AIzaSyC1S_gufyhiHyz7SilQ9_NNUBURygbB5Ow",
    web: "AIzaSyA77o_ssfzOxjaAaaUM_qexNaveUduv9rg",
  },

  PurchaseDetails: {
    CodeCanyon_Purchase_Code: "",
    Buyer_Email_Address: "",
  },
};
